<template>
  <v-row justify="center">
    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
      <!--begin::Alert-->
      <div
        class="alert alert-custom alert-light-primary fade show mb-10"
        role="alert"
      >
        <div class="alert-icon">
          <span class="svg-icon svg-icon-3x svg-icon-primary">
            <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Info-circle.svg-->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                <rect
                  fill="#000000"
                  x="11"
                  y="10"
                  width="2"
                  height="7"
                  rx="1"
                />
                <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1" />
              </g>
            </svg>
            <!--end::Svg Icon-->
          </span>
        </div>
        <div class="alert-text font-weight-bold">
          Sila semak semula maklumat yang telah diisi sebelum dihantar kepada pihak
          kami.
        </div>
      </div>
      <!--end::Alert-->

      <div>
        <v-card class="pa-6" height="auto" elevation="2">
          <div class="mb-10">
            <h4 class="primary--text font-weight-bold">Maklumat Diri</h4>
          </div>
          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Nama Pemohon</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ member.full_name }}
                  </p>
                </v-col>
              </v-flex>
            </v-row>
          </div>
          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Bank</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ member.bank_name }}
                  </p>
                </v-col>
              </v-flex>
            </v-row>
          </div>
          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">No. Akaun Bank</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ member.bank_acc }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="member.bankAccNum"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">No. Kad Pengenalan</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ member.nric }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="member.icNum"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Tarikh Lahir</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ member.birth_date | formatDate}}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="member.dateOfBirth"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Alamat Rumah</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ this.userAddress.toUpperCase() }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="this.userAddress"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Alamat Surat Menyurat</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ this.mailingAddress.toUpperCase() }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="this.mailingAddress"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Telefon Bimbit</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ member.phone_num }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="member.phoneNumber"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Telefon Rumah</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ member.home_num }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="member.housePhoneNumber"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Telefon Pejabat</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ member.office_num }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="member.officeNumber"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">E-mel</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ member.email }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="member.email"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <v-divider />

          <div class="mt-4 mb-10">
            <h4 class="primary--text font-weight-bold">Maklumat Pekerjaan</h4>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Nama Majikan</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ employment.employer_name }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="employment.name"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Pekerjaan</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ employment.occupation }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="employment.occupation"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Bahagian / Unit</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ employment.department }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="employment.unit"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Alamat Majikan</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ this.employmentAddress.toUpperCase() }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="this.employmentAddress"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">No. Telefon Pejabat</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ employment.office_num }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="employment.officeNumber"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">No. Faks</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ employment.office_fax }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="employment.faxNumber"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Pendapatan Sebulan</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    RM {{ employment.salary }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="employment.salary"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Tahun Perkhidmatan</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ employment.year_of_service }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="employment.yearOfService"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Tarikh Mula Khidmat</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ employment.commencement_date | formatDate }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="employment.dateOfService"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <v-divider />

          <div class="mt-4 mb-10">
            <h4 class="primary--text font-weight-bold">Maklumat Waris</h4>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Nama Waris</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ heir.heir_name }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="heir.fullName"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">No. Kad Pengenalan</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ heir.heir_nric }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="heir.occupation"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Tarikh Lahir</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ heir.heir_birth_date | formatDate }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="heir.dateOfBirth"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Jantina</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ heir.heir_gender }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="heir.gender"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Bangsa</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ heir.heir_race }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="heir.race"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Agama</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ heir.heir_religion }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="heir.religion"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Alamat Waris</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ this.heirAddress.toUpperCase() }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="this.heirAddress"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Poskod</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ heir.heir_postcode }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="heir.address.postCode"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Bandar</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ heir.heir_city }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="heir.address.city"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Negeri</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ heir.heir_state }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="heir.address.state"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Hubungan</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ heir.relation }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="heir.relation"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Telefon Bimbit</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ heir.heir_phone_num }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="heir.phoneNumber"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Telefon Rumah</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ heir.heir_home_num }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="heir.housePhoneNumber"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Telefon Pejabat</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <p class="subtitle-1 font-weight-bold mb-0">
                    {{ heir.heir_office_num }}
                  </p>
                  <!-- <v-text-field
                      class="subtitle-1"
                      v-model="heir.officeNumber"
                      outlined
                      clearable
                      dense
                      readonly
                      disabled
                    ></v-text-field> -->
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-flex xs4 sm4 md3>
                <v-col>
                  <p class="subtitle-1">Tandatangan</p>
                </v-col>
              </v-flex>
              <v-flex xs8 sm8 md9>
                <v-col>
                  <div>
                    <v-img
                      contain
                      :src="signature"
                      height="200"
                      width="200"
                    />
                  </div>
                </v-col>
              </v-flex>
            </v-row>
          </div>

          <!-- <v-row class="mt-10 mb-10"> -->
          <!-- <v-col cols="12" sm="12" md="12" lg="4">
              <div class="text-center">
                <v-btn block color="primary" @click="generatePDF()">
                  Cetak Borang
                </v-btn>
              </div>
            </v-col> -->
          <!-- <v-col cols="12" sm="12" md="12" lg="8">
              <div class="text-center"> -->
          <!-- <v-dialog v-model="dialog" persistents width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn block color="success" v-bind="attrs" v-on="on">
                      Hantar
                    </v-btn>
                  </template> -->

          <!-- <v-card class="pa-4">
                    <v-row>
                      <v-col cols="12">
                        <v-card-title class="px-0 py-0">
                          <span class="font-weight-bold"> Berjaya </span>
                        </v-card-title>
                        <v-card-text class="px-0 py-0">
                          <p class="body-1">
                            Maklumat anda telah berjaya dihantar dan akan
                            diproses oleh pihak kami dalam masa 24jam.
                          </p>
                        </v-card-text>
                      </v-col>

                      <v-col>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn text @click="dialog = false"> Tutup </v-btn>
                          <v-btn color="primary" @click="success()">
                            Kembali ke halaman utama
                          </v-btn>
                        </v-card-actions>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-dialog> -->
          <!-- </div>
            </v-col> -->
          <v-col class="my-6" cols="12" sm="12" md="12" lg="12">
            <v-btn
              block
              color="success"
              @click="sendData()"
              :loading="isLoading"
            >
              <span class="font-weight-bold">Seterusnya</span>
            </v-btn>
            <ConfirmationDialog
              v-model="showConfirmationDialog"
              title="Berjaya"
              content="Permohonan anda telah berjaya dihantar dan akan diproses oleh pihak kami dalam masa 24jam."
              :showProceedButton="true"
              :showBackButton="false"
              proceedButton="Kembali ke halaman utama"
              @onProceed="success()"
            ></ConfirmationDialog>
          </v-col>
          <!-- </v-row> -->
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { jsPDF } from "jspdf";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Review",

  components: {
  },

  data() {
    return {
      application: null,
      member: null,
      employment: null,
      heir: null,
      signature: null,
      showConfirmationDialog: false,
      isLoading: false,
      userAddress: "",
      mailingAddress: "",
      employmentAddress: "",
      heirAddress: "",
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mohon Pembiayaan", route: "Form" },
      { title: "Pengesahan" },
    ]);

    this.application = this.$route.params.application;
    this.member = this.$route.params.member;
    this.employment = this.$route.params.employment;
    this.heir = this.$route.params.heir;
    this.signature = this.$route.params.signature;
    // xxconsole.log(this.member);
    this.userAddress =
      this.member.home_unit +
      ", " +
      this.member.home_street +
      ", " +
      this.member.home_postcode +
      ", " +
      this.member.home_city +
      ", " +
      this.member.home_state;

    this.mailingAddress =
      this.member.mailing_unit +
      ", " +
      this.member.mailing_street +
      ", " +
      this.member.mailing_postcode +
      ", " +
      this.member.mailing_city +
      ", " +
      this.member.mailing_state;

    this.employmentAddress =
      this.employment.office_unit +
      ", " +
      this.employment.office_street +
      ", " +
      this.employment.office_postcode +
      ", " +
      this.employment.office_city +
      ", " +
      this.employment.office_state;

    this.heirAddress =
      this.heir.heir_unit +
      ", " +
      this.heir.heir_street +
      ", " +
      this.heir.heir_postcode +
      ", " +
      this.heir.heir_city +
      ", " +
      this.heir.heir_state;
  },

  methods: {
    sendData() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        this.$router.push({
          name: "EditForm",
          params: { id: this.application.id, tabIndex: 3 },
        });
      }, 2000);
    },

    success() {
      this.showConfirmationDialog = false;
      // localStorage.removeItem('userData');
      // localStorage.removeItem('employmentData');
      // localStorage.removeItem('heirData');
      this.$router.push({
        name: "EditForm",
        params: { id: 1, tabIndex: 3 },
      });
    },

    generatePDF() {
      // Default export is a4 paper, portrait, using millimeters for units
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        compress: true,
      });

      var date = new Date().toISOString().substr(0, 10);
      var bank = this.member.bankName + "/" + this.member.bankAccNum;
      var address =
        this.member.address.unit +
        ", " +
        this.member.address.street +
        ", " +
        this.member.address.postCode +
        " " +
        this.member.address.city +
        ", " +
        this.member.address.state;
      var mailingAddress =
        this.member.mailingAddress.unit +
        ", " +
        this.member.mailingAddress.street +
        ", " +
        this.member.mailingAddress.postCode +
        " " +
        this.member.mailingAddress.city +
        ", " +
        this.member.mailingAddress.state;
      var employmentAddress =
        this.employment.address.unit +
        ", " +
        this.employment.address.street +
        ", " +
        this.employment.address.postCode +
        " " +
        this.employment.address.city +
        ", " +
        this.employment.address.state;
      var heirAddress =
        this.heir.address.unit +
        ", " +
        this.heir.address.street +
        ", " +
        this.heir.address.postCode +
        " " +
        this.heir.address.city +
        ", " +
        this.heir.address.state;
      var imgData02 = ''; // eWawasan02.imgData02;
      var imgData03 = ''; // eWawasan03.imgData03;
      var imgData07 = ''; // eWawasan07.imgData07;
      var imgData08 = ''; // eWawasan08.imgData08;
      var imgData09 = ''; // eWawasan09.imgData09;

      var optText = {
        charSpace: 3.96,
        lineHeightFactor: 1.3,
      };

      // E-Wawasan03
      doc.addImage(imgData02, "PNG", 0, 0, 210, 297, "2");
      doc.setFont("Courier", "Bold");
      doc.setFontSize("9");
      doc.text(
        this.member.fullName.toUpperCase().slice(0, 20),
        88.6,
        62,
        optText
      );
      doc.text(
        this.member.fullName.toUpperCase().slice(20, 40),
        88.6,
        69,
        optText
      );
      this.member.icNum = this.member.icNum.replace(/\D/g, "");
      doc.text(this.member.icNum.slice(0, 6), 88.5, 81, optText);
      doc.text(this.member.icNum.slice(6, 8), 129.5, 81, optText);
      doc.text(this.member.icNum.slice(8, 12), 146.5, 81, optText);
      doc.text(address.toUpperCase(), 52.5, 101);
      doc.text(this.member.phoneNumber, 7.5, 122);
      doc.text(bank.toUpperCase(), 52.5, 122);
      doc.text(this.member.occupation.toUpperCase(), 135.5, 122);
      doc.text(this.member.fullName.toUpperCase(), 36.5, 250);
      doc.text(date, 36.5, 280);
      // doc.addImage(this.member.signature, 'PNG', 50.5, 246, 40, 40);

      // E-Wawasan03
      doc.addPage();
      doc.addImage(imgData03, "PNG", 0, 0, 210, 297, "3");
      doc.text(this.member.fullName.toUpperCase(), 40.5, 116);
      doc.text(this.member.icNum, 80.5, 125);
      // doc.addImage(this.member.signature, 'PNG', 34.5, 165, 40, 40);
      doc.text(date, 30.5, 201);

      // E-Wawasan07
      doc.addPage();
      doc.addImage(imgData07, "PNG", 0, 0, 210, 297, "7");
      doc.text(this.member.fullName.toUpperCase(), 66.5, 39);
      doc.text(bank.toUpperCase(), 66.5, 46);
      doc.text(this.member.icNum, 66.5, 53);
      // doc.text(this.member.dateOfBirth, 88.5, 90);
      doc.text(address.toUpperCase(), 66.5, 67);
      doc.text(mailingAddress.toUpperCase(), 66.5, 88);
      doc.text(this.member.housePhoneNumber, 81, 111);
      doc.text(this.member.officeNumber, 121, 111);
      doc.text(this.member.phoneNumber, 161, 111);
      doc.text(this.member.email, 66.5, 118.5);
      doc.text(this.member.occupation.toUpperCase(), 66.5, 126);
      doc.text(this.employment.salary, 166.5, 126);
      doc.text(this.employment.name.toUpperCase(), 66.5, 133);
      doc.text(employmentAddress.toUpperCase(), 66.5, 140.5);
      doc.text(this.heir.fullName.toUpperCase(), 66.5, 148);
      doc.text(heirAddress.toUpperCase(), 66.5, 155);
      doc.text(this.heir.housePhoneNumber, 81, 161);
      doc.text(this.heir.officeNumber, 121, 161);
      doc.text(this.heir.phoneNumber, 161, 161);
      doc.text(this.member.fullName.toUpperCase(), 60.5, 203.5);
      // doc.text(this.heir.);

      // E-Wawasan08
      doc.addPage();
      doc.addImage(imgData08, "PNG", 0, 0, 210, 297, "8");
      doc.text(this.member.fullName.toUpperCase(), 47.5, 90, optText);
      doc.text(this.member.icNum, 47.5, 105, optText);
      // doc.text(this.member.dateOfBirth, 88.5, 90);
      doc.text(address.toUpperCase().slice(0, 24), 47.5, 134, optText); //Slice 24
      doc.text(address.toUpperCase().slice(24, 48), 47.5, 139, optText); //Slice 24
      doc.text(address.toUpperCase().slice(24, 48), 47.5, 144, optText); //Slice 24
      doc.text(this.member.housePhoneNumber, 47.5, 153, optText);
      doc.text(this.member.phoneNumber, 47.5, 158, optText);
      doc.text(this.member.email, 47.5, 163, optText);
      doc.text(this.member.bankInfo.name.toUpperCase(), 47.5, 178, optText);
      doc.text(this.member.bankAccNum, 47.5, 183, optText);

      // E-Wawasan09
      doc.addPage();
      doc.addImage(imgData09, "PNG", 0, 0, 210, 297, "9");
      doc.text(this.employment.name.toUpperCase(), 53.5, 24, optText);
      doc.text(this.employment.unit.toUpperCase(), 53.5, 29, optText);
      doc.text(this.employment.occupation.toUpperCase(), 53.5, 34, optText);
      doc.text(employmentAddress.toUpperCase().slice(0, 24), 53.5, 39, optText);
      doc.text(
        employmentAddress.toUpperCase().slice(24, 48),
        53.5,
        44,
        optText
      );
      doc.text(
        employmentAddress.toUpperCase().slice(48, 72),
        53.5,
        48,
        optText
      );
      doc.text(this.employment.address.postCode, 53.5, 52.5, optText);
      doc.text(
        this.employment.address.state.toUpperCase(),
        53.5,
        57.5,
        optText
      );
      doc.text(
        this.employment.address.city.toUpperCase(),
        142.5,
        57.5,
        optText
      );
      doc.text(this.employment.officeNumber, 53.5, 62, optText);
      doc.text(this.employment.faxNumber, 53.5, 66.5, optText);
      doc.text(this.employment.salary, 53.5, 71.5, optText);
      doc.text(this.employment.yearOfService, 53.5, 75, optText);
      doc.text(this.employment.dateOfService, 53.5, 80, optText);

      doc.text(this.heir.fullName.toUpperCase(), 53.5, 93, optText);
      doc.text(this.heir.relation.toUpperCase(), 53.5, 98, optText);
      doc.text(this.heir.icNum, 53.5, 108, optText);
      doc.text(this.heir.dateOfBirth, 53.5, 117.5, optText);
      // doc.text(this.heir.gender, 53.5, 24, optText);
      // doc.text(this.heir.race, 53.5, 24, optText);
      // doc.text(this.heir.religion, 53.5, 24, optText);
      doc.text(heirAddress.toUpperCase().slice(0, 24), 53.5, 137, optText);
      doc.text(heirAddress.toUpperCase().slice(24, 48), 53.5, 142, optText);
      doc.text(heirAddress.toUpperCase().slice(48, 72), 53.5, 147, optText);
      doc.text(this.heir.address.postCode, 53.5, 152, optText);
      doc.text(this.heir.address.city.toUpperCase(), 53.5, 157, optText);
      doc.text(this.heir.phoneNumber, 53.5, 162, optText);
      doc.text(this.member.fullName.toUpperCase(), 53.5, 277);
      doc.addImage(this.member.signature, "PNG", 50.5, 260, 23.8125, 11.90625);

      // Save
      doc.save("1.pdf");
    },
  },
};
</script>
