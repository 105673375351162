var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "12", md: "12", lg: "12", xl: "12" } },
        [
          _c(
            "div",
            {
              staticClass:
                "alert alert-custom alert-light-primary fade show mb-10",
              attrs: { role: "alert" },
            },
            [
              _c("div", { staticClass: "alert-icon" }, [
                _c(
                  "span",
                  { staticClass: "svg-icon svg-icon-3x svg-icon-primary" },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          "xmlns:xlink": "http://www.w3.org/1999/xlink",
                          width: "24px",
                          height: "24px",
                          viewBox: "0 0 24 24",
                          version: "1.1",
                        },
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              stroke: "none",
                              "stroke-width": "1",
                              fill: "none",
                              "fill-rule": "evenodd",
                            },
                          },
                          [
                            _c("rect", {
                              attrs: {
                                x: "0",
                                y: "0",
                                width: "24",
                                height: "24",
                              },
                            }),
                            _c("circle", {
                              attrs: {
                                fill: "#000000",
                                opacity: "0.3",
                                cx: "12",
                                cy: "12",
                                r: "10",
                              },
                            }),
                            _c("rect", {
                              attrs: {
                                fill: "#000000",
                                x: "11",
                                y: "10",
                                width: "2",
                                height: "7",
                                rx: "1",
                              },
                            }),
                            _c("rect", {
                              attrs: {
                                fill: "#000000",
                                x: "11",
                                y: "7",
                                width: "2",
                                height: "2",
                                rx: "1",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "alert-text font-weight-bold" }, [
                _vm._v(
                  " Sila semak semula maklumat yang telah diisi sebelum dihantar kepada pihak kami. "
                ),
              ]),
            ]
          ),
          _c(
            "div",
            [
              _c(
                "v-card",
                {
                  staticClass: "pa-6",
                  attrs: { height: "auto", elevation: "2" },
                },
                [
                  _c("div", { staticClass: "mb-10" }, [
                    _c(
                      "h4",
                      { staticClass: "primary--text font-weight-bold" },
                      [_vm._v("Maklumat Diri")]
                    ),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Nama Pemohon"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.member.full_name) + " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Bank"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.member.bank_name) + " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("No. Akaun Bank"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.member.bank_acc) + " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("No. Kad Pengenalan"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [_vm._v(" " + _vm._s(_vm.member.nric) + " ")]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Tarikh Lahir"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            _vm.member.birth_date
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Alamat Rumah"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(this.userAddress.toUpperCase()) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Alamat Surat Menyurat"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          this.mailingAddress.toUpperCase()
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Telefon Bimbit"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.member.phone_num) + " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Telefon Rumah"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.member.home_num) + " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Telefon Pejabat"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.member.office_num) + " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("E-mel"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [_vm._v(" " + _vm._s(_vm.member.email) + " ")]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("div", { staticClass: "mt-4 mb-10" }, [
                    _c(
                      "h4",
                      { staticClass: "primary--text font-weight-bold" },
                      [_vm._v("Maklumat Pekerjaan")]
                    ),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Nama Majikan"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.employment.employer_name) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Pekerjaan"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.employment.occupation) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Bahagian / Unit"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.employment.department) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Alamat Majikan"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          this.employmentAddress.toUpperCase()
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("No. Telefon Pejabat"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.employment.office_num) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("No. Faks"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.employment.office_fax) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Pendapatan Sebulan"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " RM " +
                                        _vm._s(_vm.employment.salary) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Tahun Perkhidmatan"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.employment.year_of_service) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Tarikh Mula Khidmat"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            _vm.employment.commencement_date
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("div", { staticClass: "mt-4 mb-10" }, [
                    _c(
                      "h4",
                      { staticClass: "primary--text font-weight-bold" },
                      [_vm._v("Maklumat Waris")]
                    ),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Nama Waris"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.heir.heir_name) + " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("No. Kad Pengenalan"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.heir.heir_nric) + " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Tarikh Lahir"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            _vm.heir.heir_birth_date
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Jantina"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.heir.heir_gender) + " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Bangsa"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.heir.heir_race) + " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Agama"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.heir.heir_religion) + " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Alamat Waris"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(this.heirAddress.toUpperCase()) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Poskod"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.heir.heir_postcode) + " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Bandar"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.heir.heir_city) + " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Negeri"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.heir.heir_state) + " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Hubungan"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.heir.relation) + " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Telefon Bimbit"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.heir.heir_phone_num) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Telefon Rumah"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.heir.heir_home_num) + " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Telefon Pejabat"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "subtitle-1 font-weight-bold mb-0",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.heir.heir_office_num) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "", sm4: "", md3: "" } },
                            [
                              _c("v-col", [
                                _c("p", { staticClass: "subtitle-1" }, [
                                  _vm._v("Tandatangan"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs8: "", sm8: "", md9: "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "div",
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        contain: "",
                                        src: _vm.signature,
                                        height: "200",
                                        width: "200",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "my-6",
                      attrs: { cols: "12", sm: "12", md: "12", lg: "12" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            block: "",
                            color: "success",
                            loading: _vm.isLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sendData()
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Seterusnya"),
                          ]),
                        ]
                      ),
                      _c("ConfirmationDialog", {
                        attrs: {
                          title: "Berjaya",
                          content:
                            "Permohonan anda telah berjaya dihantar dan akan diproses oleh pihak kami dalam masa 24jam.",
                          showProceedButton: true,
                          showBackButton: false,
                          proceedButton: "Kembali ke halaman utama",
                        },
                        on: {
                          onProceed: function ($event) {
                            return _vm.success()
                          },
                        },
                        model: {
                          value: _vm.showConfirmationDialog,
                          callback: function ($$v) {
                            _vm.showConfirmationDialog = $$v
                          },
                          expression: "showConfirmationDialog",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }